<template>
  <div v-if="shouldDisplayComponent">
    <fdk-cart class="product__actions" ref="cart">
      <template slot-scope="cart">
        <button style="display: none" @click="handleReload(cart)">
          Reload
        </button>
      </template>
    </fdk-cart>
    <cart.newProduct> </cart.newProduct>

    <!-- Full-screen Loader -->
    <div v-if="loading" class="engage-loading-overlay">
      <img
        src="./assets/animations/spinner.svg"
        alt="Loading..."
        class="spinner"
      />
    </div>

    <!-- Points Container -->
    <div class="engage-points-container">
      <div class="engage-points-card-left">
        <img src="./assets/images/coins.svg" alt="Star Icon" />
        <div class="engage-points-details">
          <!-- Update Heading Dynamically -->
          <div class="engage-points-heading">
            <template v-if="engageCheckbox">
              {{ applicableLoyaltyPoints }} points redeemed
            </template>
            <template v-else>
              You've {{ engageLoyaltyPoints }} points
            </template>
          </div>
          <!-- Update Subtext Dynamically -->
          <div class="engage-points-subtext">
            <template v-if="engageCheckbox">
              You've saved ₹{{ applicableDiscountAmount }}
            </template>
            <template v-else>
              Save additional ₹{{ applicableDiscountAmount }}
            </template>
          </div>
        </div>
      </div>
      <!-- Redeem Button -->
      <div class="engage-redeem-button" @click="handleEngageCheckbox">
        <img
          v-if="engageCheckbox"
          src="./assets/images/cross.svg"
          alt="Cross Icon"
        />
        <span v-else>REDEEM</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  applyOrRemoveCartInjection,
  rewardDetailsOfUser,
} from "../../services/cart.service";

export default {
  name: "rewards-view",
  data() {
    return {
      engageLoyaltyPoints: 0, // Total loyalty points
      applicableLoyaltyPoints: 0, // Applicable loyalty points
      applicableDiscountAmount: 0,
      engageCheckbox: false, // Engage checkbox state
      disableCheckbox: false, // Disable button state
      loading: false, // Loading state for full-page spinner
      shouldDisplayComponent: true,
    };
  },
  async mounted() {
    // Listen to cart update and removal events
    window.FPI.event.on("cart.update", this.fetchUserEngagePointsOnCart);
    window.FPI.event.on("cart.remove", this.fetchUserEngagePointsOnCart);

    // Initial fetch for loyalty points
    await this.fetchUserEngagePointsOnCart();
    this.handleReload();
  },
  methods: {
    /**
     * Extract URL parameters
     */
    getUrlParams() {
      const url = new URL(window.location.href);
      const cartId =
        url.searchParams.get("cart_id") || url.searchParams.get("id");
      const buyNow = url.searchParams.get("buy_now") === "true";
      return { cartId, buyNow };
    },

    /**
     * Fetch loyalty points and update UI state
     */
    async fetchUserEngagePointsOnCart() {
      try {
        const { cartId, buyNow } = this.getUrlParams();
        const payload = {
          b: true,
          i: true,
          buy_now: buyNow,
          ...(cartId && { cart_id: cartId }),
        };

        const response = await rewardDetailsOfUser(payload);
        if (response?.status === 200) {
          const loyaltyPoints =
            response.data?.breakup_values?.loyalty_points || {};
          this.shouldDisplayComponent =
            loyaltyPoints.total > 0 && loyaltyPoints.applicable > 0;
          this.engageLoyaltyPoints = loyaltyPoints.total || 0;
          this.applicableLoyaltyPoints = loyaltyPoints.applicable || 0;
          this.applicableDiscountAmount = loyaltyPoints.discountAmount || 0;
          this.engageCheckbox = !!loyaltyPoints.is_applied;
          this.disableCheckbox = this.applicableLoyaltyPoints <= 0;
        } else {
          console.error("Failed to fetch loyalty points");
        }
        await this.handleReload(); // Reload cart after fetching points
      } catch (error) {
        console.error("Error fetching engage points:", error);
      }
    },

    /**
     * Handle Redeem Button Click
     */
    async handleEngageCheckbox() {
      try {
        this.loading = true; // Show loader
        this.disableCheckbox = true;
        const pointsStatus = !this.engageCheckbox; // Send `true` if not applied, `false` otherwise
        await this.applyOrRemoveCartInjection(pointsStatus);
        await this.handleReload(); // Reload cart after fetching points
      } catch (error) {
        console.error("Error handling engage checkbox:", error);
      } finally {
        this.disableCheckbox = false;
        this.loading = false; // Hide loader
      }
    },

    /**
     * Apply or remove cart injection
     */
    async applyOrRemoveCartInjection(pointsStatus) {
      try {
        const { cartId, buyNow } = this.getUrlParams();
        const payload = {
          points: pointsStatus, // Send true when points are not applied
          ...(cartId && { cart_id: cartId }),
          ...(buyNow !== undefined && { buy_now: buyNow }),
          ...(buyNow && { b: buyNow }),
        };
        const response = await applyOrRemoveCartInjection(payload);
        if (response?.status === 200) {
          const loyaltyPoints =
            response.data?.breakup_values?.loyalty_points || {};
          this.shouldDisplayComponent =
            loyaltyPoints.total > 0 && loyaltyPoints.applicable > 0;
          this.engageLoyaltyPoints = loyaltyPoints.total || 0;
          this.applicableLoyaltyPoints = loyaltyPoints.applicable || 0;
          this.applicableDiscountAmount = loyaltyPoints.discountAmount || 0;
          this.engageCheckbox = !!loyaltyPoints.is_applied;
          this.disableCheckbox = this.applicableLoyaltyPoints <= 0;
        } else {
          console.error("Failed to fetch loyalty points");
        }
        // await this.handleReload(); // Reload cart after applying/removing injection
      } catch (error) {
        console.error("Error applying/removing cart injection:", error);
        this.shouldDisplayComponent = false;
      }
    },

    /**
     * Reload cart state
     */
    async handleReload() {
      if (this.$refs["cart"]) {
        await this.$refs["cart"].getCart();
      } else {
        console.log(`refs cart value not found`);
      }
    },
  },
};
</script>

<style lang="less">
:root {
  --primaryColor: #023047;
  --secondaryColor: #000507;
  --accentColor: #bea5a9;
  --linkColor: #552531;
  --bgColor: #fff9f3;
  --pageBackground: #fff9f3;
  --themeAccent: #bea5a9;
  --textHeading: #000507;
  --textBody: #010e15;
  --textLabel: #35596c;
  --textSecondary: #678391;
  --buttonPrimary: #023047;
  --buttonSecondary: #ffffff;
  --buttonLink: #552531;
  --saleBadgeBackground: #f1faee;
  --saleBadgeText: #410102;
  --saleDiscountText: #1867b0;
  --saleTimer: #e63946;
  --headerBackground: #ffffff;
  --headerNav: #000507;
  --headerIcon: #000507;
  --footerBackground: #023047;
  --footerBottomBackground: #022a3f;
  --footerHeadingText: #f9f6f6;
  --footerBodyText: #ffffff;
  --footerIcon: #ffffff;
  --dialogBackground: #ffffff;
  --overlay: #010e15;
  --dividerStokes: #f2edee;
  --highlightColor: #010e15;
  --successBackground: #e9f9ed;
  --successText: #1c958f;
  --errorBackground: #fff5f5;
  --errorText: #b24141;
  --informationBackground: #fff359;
  --informationText: #d28f51;
}

.engage-points-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--dividerStokes);
  background-color: var(--pageBackground);
}

.engage-points-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--pageBackground);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #dcdcdc; /* Replace box-shadow with a light border */
}

#engage-app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  height: inherit;
}

.engage-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
}

.engage-points-heading {
  font-size: 14px;
  font-weight: 600;
  color: var(--textHeading);
  line-height: 140%;
}

.engage-points-subtext {
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  line-height: 140%;
  color: var(--textBody);
}

.engage-redeem-button {
  font-size: 12px;
  font-weight: 600;
  color: var(--buttonLink);
  line-height: 140%;
  cursor: pointer;
}

.engage-redeem-button:hover {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.engage-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.engage-spinner {
  width: 100px;
  height: 100px;
}

.engage-points-card-left {
  display: flex;
}

.engage-points-details {
  padding-left: 12px;
}
</style>
